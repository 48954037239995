p {
  margin: 0;
}

h2 {
  margin: 0;
}

option {
  font-size: 16px;
}

mark {
  background-color: transparent;
  color: #002655;
  font-weight: bold;
}

.grecaptcha-badge {
  width: 70px !important;
  overflow: hidden !important;
  transition: all 0.3s ease !important;
  left: 0px !important;
}
.grecaptcha-badge:hover {
  width: 256px !important;
}

.move-zoho-chat {
  bottom: 95px !important;
}

//Google Login Icon
.st0 {
  fill: #e0e0e0;
}
.st1 {
  fill: #fff;
}
.st2 {
  clip-path: url(#SVGID_2_);
  fill: #fbbc05;
}
.st3 {
  clip-path: url(#SVGID_4_);
  fill: #ea4335;
}
.st4 {
  clip-path: url(#SVGID_6_);
  fill: #34a853;
}
.st5 {
  clip-path: url(#SVGID_8_);
  fill: #4285f4;
}

//Facebook social media icon
.facebook-1 {
  fill: #fff;
}
.facebook-2 {
  fill: #192a53;
}

//instagram icon
.instagram-1 {
  fill: #fff;
}
.instagram-2 {
  fill-rule: evenodd;
}
.instagram-2 {
  fill: #192a53;
}

//twitter icon
.twitter-1 {
  fill: #fff;
}
.twitter-2 {
  fill: #192a53;
}

//notification icon
.notification-1,
.notification-2 {
  fill: #fff;
}
.notification-1 {
  fill-rule: evenodd;
}

//user icon
.user-1 {
  fill: #fff;
  fill-rule: evenodd;
}

//head icon
.head-1 {
  fill: #3e4166;
}

//apple icon
.apple-1 {
  fill: #3e4166;
  fill-rule: evenodd;
}

//alcohol icon
.alcohol-1 {
  fill: #3e4166;
  fill-rule: evenodd;
}

//question-icon
.question-1 {
  fill: #192a53;
}

//arrow-right
.arrow-right-1 {
  fill: #192a53;
}

//leaves
.leaves-1 {
  fill: #3e4166;
}
.leaves-2 {
  fill: #fff;
}

//add-circle
.add-circle-1 {
  fill: #3e4166;
}
.add-circle-2 {
  fill: #fff;
  fill-rule: evenodd;
}

//minus-circle
.minus-circle-1 {
  fill: #3e4166;
}
.minus-circle-2 {
  fill: #fff;
  fill-rule: evenodd;
}

//exclamation-circle
.exclamation-circle-1 {
  fill: #3e4166;
}
.exclamation-circle-2 {
  fill: #fff;
}

//journal-entries
.journal-entries-1 {
  fill: #fff;
}

//doctor
.doctor-1 {
  fill: white;
}

.asip-header-icon-0 {
  fill: #ffffff;
}
.asip-header-icon-1 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #ffffff;
}

//MUI
.MuiButton-label {
  a {
    &:focus,
    &:hover,
    &:visited,
    &:link,
    &:active {
      text-decoration: none;
      color: inherit;
    }
  }
}

.MuiPickersToolbar-toolbar {
  background-color: #5db2e3 !important;
}

//carousel
.slick-dots {
  bottom: -50px !important;
}

//nprogress bar
#nprogress .bar {
  background: #5db2e3 !important;
  height: 6px !important;
}

#nprogress .peg {
  box-shadow: 0 0 10px #5db2e3, 0 0 5px #5db2e3 !important;
}

#nprogress .spinner-icon {
  border-top-color: #5db2e3 !important;
  border-left-color: #5db2e3 !important;
}

.dl-button-overlay {
  button {
    opacity: 0;
    transition: "all 0.3s ease-out";
  }
  &:hover {
    button {
      opacity: 1;
    }
  }
  @media screen and (max-width: 816px) {
    opacity: 1 !important;
  }
}

.slider__handle {
  svg {
    width: 48px;
    height: 48px;
  }
}

.left {
  float: left;
  padding: 0 10px 0 0;
}

.right {
}

.flip-svg {
  -moz-transform: scale(-1, -1);
  -o-transform: scale(-1, -1);
  -webkit-transform: scale(-1, -1);
  transform: scale(-1, -1);
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis-small {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 24px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #002655;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis-small div {
  position: absolute;
  top: 8px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #002655;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
.lds-ellipsis-small div:nth-child(1) {
  left: 4px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis-small div:nth-child(2) {
  left: 4px;
  animation: lds-ellipsis2-small 0.6s infinite;
}
.lds-ellipsis-small div:nth-child(3) {
  left: 16px;
  animation: lds-ellipsis2-small 0.6s infinite;
}
.lds-ellipsis-small div:nth-child(4) {
  left: 28px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
@keyframes lds-ellipsis2-small {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(12px, 0);
  }
}

.md-title {
  margin-bottom: 16px;
  font-size: 20px;
  + p {
    margin-top: 16px;
  }
}

.md-paragraph {
  margin-bottom: 16px;
  font-size: 16px;
}

.md-list-item {
  margin-bottom: 8px;
  font-size: 16px;
}

.md-list {
  margin-top: 8px;
}
